import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Grid from '@mui/material/Grid';

import './Avatar.css'

const Links = () => {




    return (
        <Card className="avatar-card" sx={{  maxHeight: '700px',  bgcolor: 'white'}}>
          

            <CardActionArea>
           
            <CardContent sx={{ bgcolor: 'lightgray', height: '70px' }}>
  
        <Grid container spacing={0}>
            <Grid item xs={2} ></Grid>
            <Grid item xs={8}>
                <Typography variant="h6" color="text.secondary" sx={{ flex: '1', paddingTop:'5px', textAlign: 'center' }}>
                Donate crypto
            </Typography>
            </Grid>
            <Grid item xs={2} >
            <button style={{float:'right'}} className='mdl-button'>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/darrensoohoo/">
                    <img src="https://img.icons8.com/color/40/000000/linkedin.png" alt="linkedin icon" />
                </a>
            </button>

            </Grid>
        </Grid>
       
 
  
</CardContent>
            </CardActionArea>
        </Card>
    );
}

export default Links;
