import React, { useState, useEffect } from 'react';
import Avatar from './Avatar';
import AboutMe from './AboutMe';
import Post from './Post';
import Loader from './Loader';
import Footer from './Footer';
import './Landing.css';
import Pdf from './../images/resume.pdf';
import { Provider } from 'react-redux';
import store from '../../store';
import { loadUser } from './../../actions/auth';
import setAuthToken from '../../utils/setAuthToken';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Links from './Links';
import Fab from '@mui/material/Fab';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import Hidden from '@mui/material/Hidden'

if (localStorage.token) {
    setAuthToken(localStorage.token);
}

const Landing = ({
    darkMode,
    logout,
    auth,
    isAuthenticated,

}) => {
    const background = darkMode ? require('./../images/mountain-cloud-dark.png') : require('./../images/mountain-cloud.png');
    const [posts, setPosts] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);

    const timeConverter = (unix_time) => {
        const date = new Date(unix_time);
        const month = date.getUTCMonth(unix_time);
        const day = date.getDate();
        const year = date.getFullYear();
        const formattedTime = month + 1 + '/' + day + '/' + year;
        console.log(formattedTime);
        return formattedTime;
    };

    useEffect(() => {
        fetch('/api/posts')
            .then((res) => res.json())
            .then((fetchedPosts) => {
                setPosts(fetchedPosts);
                setIsLoaded(true);
            })
            .catch((e) => {
                console.log(`An error occurred: ${e}`);
            });

        store.dispatch(loadUser());
    }, []);

    return (
        <Provider store={store}>
            <main
                style={{
                    backgroundImage: 'url(' + background + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center center',
                    backgroundAttachment: 'fixed',
                    backgroundRepeat: 'no-repeat'

                }}
            >
                <Stack direction='column' alignItems={'center'}>
                    <Box
                        style={{
                            maxWidth: '1000px',
                            paddingBottom: '70px',
                            paddingTop: '20px',
                        }}
                    >
                        <Box style={{ padding: '10px' }}>
                            <Stack
                                direction={{ xs: 'column', sm: 'row' }}
                                spacing={{sm:0, md: 2 }}
                                sx={{ width: '100%' }}
                            >
                                <AboutMe auth={auth} isAuthenticated={isAuthenticated} />

                                <Hidden lgDown>
                                    <Stack direction='column' spacing={2} >
                                        <Avatar />
                                    </Stack>
                                </Hidden>
                            </Stack>
                            <Stack direction='column' spacing={3} sx={{ pt: 3 }}>
                                {isLoaded ? (
                                    posts.map((post, index) => (
                                        <Post
                                            key={index}
                                            post={post}
                                            darkMode={darkMode}
                                            timeConverter={timeConverter}
                                        />
                                    ))
                                ) : (
                                    <Loader />
                                )}
                            </Stack>
                        </Box>
                        <Footer />
                        <Fab
                            style={{
                                backgroundColor: '#E91B62',
                                borderRadius: '2px',
                            }}
                            sx={{
                                pl: 2,
                                pr: 2,
                                pt: 1,
                                pb: 1,
                                width: 'auto',
                                height: 'auto',
                                color: 'white',
                            }}
                            target='_blank'
                            //href={Pdf}
                            href="https://onedrive.live.com/view.aspx?resid=371809EB9B5FDEF0!69528&authkey=!AOSTPmKKO1EXi2E"
                            id='view-source'
                        >
                            View Resume
                        </Fab>
                    </Box>
                </Stack>
            </main>
        </Provider>
    );
};




Landing.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout })(Landing);