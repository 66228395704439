

export const createPost = (post) => {
    return fetch(`/api/posts/create/`, {
        method: "POST",
        headers: {
            Accept: "application/json"
        },
        body: post
    })
        .then(response => {
            return response.json();
        })
        .catch(err => {
            console.log(err);
        });
};


/**
 * to perform crud on product
 * get all products
 * get a single product
 * update single product
 * delete single product
 */


export const deletePost = (postId) => {
    return fetch(`/api/posts/post/${postId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        }
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const getPost = postId => {
    return fetch(`/api/posts/post/${postId}`, {
        method: "GET"
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};

export const updatePost = (postId, post) => {
    return fetch(`/api/posts/post/${postId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json"
        },
        body: post
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));
};
