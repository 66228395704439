import React, { Fragment, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import TextField from '@material-ui/core/TextField';
import './Login.css'

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const { email, password } = formData;

  const onChange = e =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async e => {
    e.preventDefault();
    login(email, password);
  };

  if (isAuthenticated) {
    return <Redirect to='/' />;
  }

  return (
    <Fragment>
                <div id="login-container">
                <div id="login-jumbotron" class="jumbotron">   
                    <h1 className='large text-primary'>Sign In</h1>
                    <p className='lead'>
                        <i className='fas fa-user' /> Sign Into Your Account
                    </p>
                    <form className='form' onSubmit={e => onSubmit(e)}>
              
                        <div>
                        <TextField 
                            onChange={e => onChange(e)}
                            value={email}
                            type="email" 
                            name="email" 
                            placeholder="Email Address"
                            margin="normal"
                            variant="outlined"
                            label="Email"
                            className="col-sm-6 login-selection-field"
                        />

                        <TextField 
                            onChange={e => onChange(e)}
                            value={password}
                            type="password" 
                            name="password" 
                            placeholder="Password"
                            margin="normal"
                            variant="outlined"
                            label="Password"
                            className="col-sm-6 login-selection-field"
                        />
                        </div>
                        
                      
               
                     
                        <input type='submit' className='mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent' value='Login' /> 
                        <p id="no-account" className='my-1'>
                        Don't have an account? <Link className=" no-account" to='/register'>Sign Up</Link>
                        </p>

                        <a href="https://www.coinbase.com/oauth/authorize?client_id=66908b2724edb95ee48a3ddfc05de58e88dc336734396df6418e6fd97979d71a&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=wallet:user:read wallet:user:email wallet:contacts:read wallet:accounts:read wallet:payment-methods:read wallet:payment-methods:delete wallet:payment-methods:limits wallet:transactions:read wallet:transactions:request wallet:buys:read wallet:sells:read wallet:trades:read wallet:addresses:read wallet:addresses:create wallet:orders:read wallet:checkouts:read wallet:deposits:read wallet:withdrawals:read wallet:notifications:read wallet:supported-assets:read wallet:accounts:update wallet:accounts:create wallet:accounts:delete wallet:buys:create wallet:sells:create wallet:trades:create wallet:orders:create wallet:deposits:create wallet:withdrawals:create wallet:user:update wallet:checkouts:create wallet:orders:refund wallet:transactions:transfer wallet:transactions:send external:wallet-addresses:write">Login with Coinbase</a>
                   
                  
                    </form>
  
                    </div>
                    </div>
    </Fragment>
  );
};
Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool
};
const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated
});
export default connect(
  mapStateToProps,
  { login }
)(Login);
