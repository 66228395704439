import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Register from '../auth/Register';
import Login from '../auth/Login';
import Landing from '../layout/Landing';
import Alert from '../layout/Alert';
import PostForm from '../posts/PostForm';
import EditForm from '../posts/EditForm';
import NotFound from '../layout/NotFound';
import PrivateRoute from '../routing/PrivateRoute';


const Routes = () => {
  return (
  
    <section >
      <Alert />
      <Switch>
        <Route exact path='/' component={Landing} />
        <Route exact path='/register' component={Register}  />
        <Route exact path='/login' component={Login} />
        <PrivateRoute exact path='/post-form' component={PostForm}  />
        <PrivateRoute exact path='/edit-form/:postId' component={EditForm} />
        <Route component={NotFound} />
      </Switch>
 
    </section>

  );
};

export default Routes;
