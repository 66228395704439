import React, { Fragment, useState, useEffect } from 'react';
import './PostForm.css';
import TextField from '@material-ui/core/TextField';
import Message from './Message';
import { useHistory } from 'react-router-dom';


  const PostForm = (props)  => {
    const history = useHistory();
    const [values, setValues] = useState({
      name: "",
      description: "",
      technology: "",
      github: "",
      demo: "",
      projectdate: "",
      photo: "",
      message: "",
      formData: ""
    });

    const {
      name,
      description,
      technology,
      github,
      demo,
       projectdate,
       photo,
      message,
      formData
    } = values;

    const init = () => {
      setValues({
        ...values,
        formData: new FormData()
      })
  };

  useEffect(() => {
      init();
  }, []);
//   useEffect(() => {
//     init();
// });

const handleChange = name => event => {
  const value = name === "photo" ? event.target.files[0] : event.target.value;
  formData.set(name, value);
  console.log("New value:", value); // Add this line to see the new value
  setValues({ ...values, [name]: value });
};

    const clickSubmit = event => {
      event.preventDefault();
      setValues({ ...values, error: "", loading: true });

      // Place the fetch inside a callback function or useEffect
      // to ensure that formData is fully populated before the request is sent
      fetch(`/api/posts/post/create`, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: formData
      })
        .then(response => {
          console.log("RESPONSE", response)
          history.push('/');
          return response.json();
        })
        .then(data => {
          // Handle the response data here
         
          console.log("data",data);
        })
        .catch(err => {
          console.log(err);
        });

      setValues({
        ...values,
        name: "",
        description: "",
        technology: "",
        github: "",
        demo: "",
        projectdate: "",
        photo: "",
        loading: false,
      });
    };

    return (
                <Fragment>
                  {message ? <Message msg={message} /> : null}
                    <div id="post-container">
                      <div id="post-jumbotron" className="jumbotron">
                        <h3 className='large text-primary'>Post</h3>
                        <p className='lead'>
                            <i className='fas fa-user' /> Submit another project
                        </p>
                        <form onSubmit={clickSubmit}>
                          <TextField
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={name}
                                onChange={handleChange("name")}
                                variant="outlined"
                                label="Name"
                                className="post-title-field"
                          />

                              <TextField
                                type='text'
                                name='github'
                                value={github}
                                onChange={handleChange("github")}
                                variant="outlined"
                                label="GitHub link"
                                className="post-form-field"
                   
                            />
                              <TextField
                                type='text'
                                name='demo'
                                value={demo}
                                onChange={handleChange("demo")}
                                variant="outlined"
                                label="Demo link"
                                className="post-form-field"
                             
                            />

                            <TextField
                                type='text'
                                name='technology'
                                value={technology}
                                onChange={handleChange("technology")}
                                variant="outlined"
                                label="Technology"
                                className="post-form-field"
                            />

                            <TextField
                                  id="standard-multiline-static"
                                  className='post-description-field'
                                  name='description'
                                  label="Project description"
                                  multiline
                                  rows="4"
                                  defaultValue="Project description"
                                  variant="outlined"                                  
                                  margin="normal"
                                  onChange={handleChange("description")}
                                  value={description}
                                />

                              <TextField
                                        id="projectdate"
                                        type="date"
                                        label="Date Completed"
                                        value={values.projectdate}
                                        defaultValue={Date.now}
                                        onChange={handleChange('projectdate')}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />

                           


                    
                                <div className="custom-file">
                                <input
                                  onChange={handleChange("photo")}
                                  type="file"
                                  name="photo"
                                  accept="image/*"
                                  className="post-form-field"
                                  id="photo"
                                />
                                </div>
                          <input className='mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent submit-button' value='Submit' type="submit" />
                        </form>
                      </div>
                    </div>


                </Fragment>
            )
    }

  

export default PostForm;