import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import ShowImage from "./ShowImage";
import { Link } from 'react-router-dom';
import { Stack } from '@mui/system';
import Divider from '@mui/material/Divider';
import { useTheme } from '@mui/material/styles';
import LazyLoad from 'react-lazyload';
import Spinner from './Spinner';

import { lighten } from 'polished';

export default function Post({ post, timeConverter, darkMode } ) {
  const theme = useTheme();
    const logo = require('./../images/ds.ico')
    const linkColor = theme.palette.primary.main;
  const [imageLoaded, setImageLoaded] = useState(false);


  useEffect(() => {

  },[darkMode]) 

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
    return (
        <Card sx={{ maxWidth: '100%' }}>
                  <CardContent  sx={{bgcolor: theme.palette.primary.main}}>
                  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    {/* <ShowImage item={post} /> */}
            <LazyLoad height={200} offset={100}>
              <img
                src={`/api/posts/post/photo/${post._id}`}
                alt={post.name}
                className="mb-3"
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                onLoad={handleImageLoad}
              
              />
              {/* {!imageLoaded && <Spinner />} */}
            </LazyLoad>


                </div>

                  </CardContent>
                  <Typography padding={'25px'}>
                    {post.description}
                  </Typography>
              <Divider />
            <CardContent>
             <Stack direction="row" spacing={2}>
            <img className="minilogo" style={{ maxWidth: '70px', maxHeight: '100%', borderRadius:'5%'}} alt="mini logo" src={logo}></img>
                 <div>
             <Typography gutterBottom variant="h5" component="div">
                <Link style={{ textDecoration: 'none', color: darkMode ?  lighten(0.2, linkColor) : `${linkColor}` }} className="project-name" to={`/edit-form/${post._id}`}> <strong>{post.name}</strong></Link>                </Typography>
              <Typography variant="body1" >
                <span  className="date-text post-link">   
                  {post.github ? <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} href={`${post.github}`}> <font color={darkMode ? 'white' : `${linkColor}`}>GitHub</font> </a> : ""}
                  {post.github && post.demo ? <font color={darkMode ? 'white':'#263238'}> | </font>  : ''}
                  {post.demo ? <a target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} href={`${post.demo}`}> <font color={darkMode ? 'white' : `${linkColor}`}>View App</font></a> : ""}
                  </span>
               
                </Typography>
              <Typography variant="subtitle2" sx={{pt:1}} >
                <font color={darkMode ? 'white' : `#4B5871`}><b>{timeConverter(post.projectdate)}</b></font>
                </Typography>

             </div>

            </Stack>
           
 
            
            </CardContent>
        </Card>
    );
}