import { deepPurple } from "@mui/material/colors";
import { red } from "@mui/material/colors";
import { blue } from "@mui/material/colors";
import { green } from "@mui/material/colors";
import { blueGrey } from "@mui/material/colors";
import { cyan } from "@mui/material/colors";
import { lightBlue } from "@mui/material/colors";
import { purple } from "@mui/material/colors";
import { teal } from "@mui/material/colors";
import { pink } from "@mui/material/colors";
import { orange } from "@mui/material/colors";
import { deepOrange } from "@mui/material/colors";
import { yellow } from "@mui/material/colors";
import { brown } from "@mui/material/colors";
import { amber } from "@mui/material/colors";
import { grey } from "@mui/material/colors";
import { lightGreen } from "@mui/material/colors";
const colors  =
    [   { id: 'pink["500"]', value: pink["500"] }, // 0
        { id: 'pink["A200"]', value: pink["A200"] }, // 1
        { id: 'pink["A400"]', value: pink["A400"] }, // 2
        { id: 'pink["A700"]', value: pink["A700"] }, // 3
        { id: 'amber["A200"]', value: amber["A200"] }, // 4
        { id: 'amber["A400"]', value: amber["A400"] },  // 5
        { id: 'amber["A700"]', value: amber["A700"] }, // 6
        { id: 'blue["A200"]', value: blue["A200"] }, // 7
        { id: 'blue["A400"]', value: blue["A400"] }, // 8
        { id: 'blue["A700"]', value: blue["A700"] }, // 9
        { id: 'blueGrey["300"]', value: blueGrey["300"] }, // 10
        { id: 'blueGrey["400"]', value: blueGrey["400"] }, // 11
        { id: 'blueGrey["500"]', value: blueGrey["500"] }, // 12
        { id: 'brown["700"]', value: brown["700"] }, // 13
        { id: 'brown["800"]', value: brown["800"] }, // 14
        { id: 'brown["900"]', value: brown["900"] }, // 15
        { id: 'cyan["A200"]', value: cyan["A200"] }, // 16
        { id: 'cyan["A400"]', value: cyan["A400"] }, // 17
        { id: 'cyan["A700"]', value: cyan["A700"] }, // 18
        { id: 'deepOrange["A200"]', value: deepOrange["A200"] }, // 19
        { id: 'deepOrange["A400"]', value: deepOrange["A400"] }, // 20
        { id: 'deepOrange["A700"]', value: deepOrange["A700"] }, // 21
        { id: 'deepPurple["A200"]', value: deepPurple["A200"] }, // 22
        { id: 'deepPurple["A400"]', value: deepPurple["A400"] }, // 23
        { id: 'deepPurple["A700"]', value: deepPurple["A700"] }, // 24
        { id: 'green["A200"]', value: green["A200"] }, // 25
        { id: 'green["A400"]', value: green["A400"] }, // 26
        { id: 'green["A700"]', value: green["A400"] }, // 27
        { id: 'lightBlue["A200"]', value: lightBlue["A200"] }, // 28
        { id: 'lightBlue["A400"]', value: lightBlue["A400"] }, // 29
        { id: 'lightBlue["A700"]', value: lightBlue["A700"] }, // 30
        { id: 'orange["A400"]', value: orange["A400"] }, // 31
        { id: 'orange["A400"]', value: orange["A400"] }, // 32
        { id: 'orange["A700"]', value: orange["A700"] }, // 33
        { id: 'purple["A200"]', value: purple["A200"] }, // 34
        { id: 'purple["A400"]', value: purple["A400"] }, // 35
        { id: 'purple["A700"]', value: purple["A700"] }, // 36
        { id: 'red["A200"]', value: red["A200"] }, // 37
        { id: 'red["A400"]', value: red["A400"] }, // 38
        { id: 'red["A700"]', value: red["A700"] }, // 39
        { id: 'teal["A200"]', value: teal["A200"] }, // 40
        { id: 'teal["A400"]', value: teal["A400"] }, // 41
        { id: 'teal["A700"]', value: teal["A700"] }, // 42
        { id: 'lightGreen["A100"]', value: lightGreen["A100"] }, // 43
        { id: 'lightGreen["A200"]', value: lightGreen["A200"] }, // 44
        { id: 'lightGreen["A400"]', value: lightGreen["A400"] } // 45
    ]

export default colors;