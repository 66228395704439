import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";



export default function CircularColor() {
  return (
    <Stack className="spinner-container" style={{ paddingTop: "20px" }} sx={{ color: "gray.700" }} spacing={1} direction="column">
    
      <CircularProgress sx={{ marginTop: "30px", color:'white' }}  />
    </Stack>
  );
}
