import React, { Fragment, useState, useEffect } from 'react';
import './EditForm.css';
import TextField from '@material-ui/core/TextField';
import Message from './Message';
import { getPost } from "../layout/apiAdmin";
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';

  const EditForm = ({ match })  => {
    const history = useHistory();
    const [values, setValues] = useState({
      name: "",
      description: "",
      technology: "",
      github: "",
      demo: "",
      projectdate: "",
      photo: "",
      message: "",
      formData: ""
    });

    const {
      name,
      description,
      technology,
      github,
      demo,
      projectdate,
      photo,
      message,
      formData
    } = values;


    const init = (postId) => {
      getPost(postId).then(data => {
        if(data.error) {
          setValues({ ...values, error: data.error });
        } else {
          setValues({
            ...values,
            name: data.name,
            description: data.description,
            technology: data.technology,
            github: data.github,
            demo: data.demo,
            projectdate: data.data,
            photo: data.photo,
            formData: new FormData()
          });
        }
      })
    }

  useEffect(() => {
      init(match.params.postId);
  }, []);


    const handleChange = name => event => {
      const value =
          name === "photo" ? event.target.files[0] : event.target.value;
      formData.set(name, value);
      setValues({ ...values, [name]: value });
  };

  // const destroy = () => {
  //   fetch(`/api/posts/post/${match.params.postId}`, {
  //     method: "DELETE",
  //     headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json"
  //     }
  // })
  //     .then(response => {
  //         return response.json();
  //     })
  //     .catch(err => console.log(err));
  // }


    const clickSubmit = event => {
      event.preventDefault();
      setValues({ ...values, error: "", loading: true });
         
      fetch(`/api/posts/post/${match.params.postId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json"
        },
        body: formData
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));

        setValues({
          ...values,
          name: "",
          description: "",
          technology: "",
          github: "",
          demo: "",
          projectdate: "",
          photo: "",
          loading: false,
        });
        
      history.push('/');

    };

    const clickDelete = event => {
      event.preventDefault();
      setValues({ ...values, error: "", loading: true });
         
      fetch(`/api/posts/post/${match.params.postId}`, {
        method: "DELETE",
        headers: {
            Accept: "application/json"
        },
        body: formData
    })
        .then(response => {
            return response.json();
        })
        .catch(err => console.log(err));

        setValues({
          ...values,
          name: "",
          description: "",
          technology: "",
          github: "",
          demo: "",
          projectdate: "",
          photo: "",
          loading: false,
        });
        
      history.push('/');

    };

    return (
                <Fragment>
                  {message ? <Message msg={message} /> : null}
                    <div id="post-container">
                      <div id="post-jumbotron" className="jumbotron">
                        <h3 className='large text-primary'>Edit Post</h3>
                        <p className='lead'>
                            <i className='fas fa-user' />
                        </p>
                        <form onSubmit={clickSubmit}>
                        <TextField
                                id="name-field"
                                type='text'
                                placeholder='Name'
                                name='name'
                                value={name}
                                onChange={handleChange("name")}
                                variant="outlined"
                                label="Name"
                                className="post-title-field"
                          />

                              <TextField
                                id="github-field"
                                type='text'
                                name='github'
                                value={github}
                                onChange={handleChange("github")}
                                variant="outlined"
                                label="GitHub link"
                                className="post-form-field"
                   
                            />
                              <TextField
                                type='text'
                                name='demo'
                                value={demo}
                                onChange={handleChange("demo")}
                                variant="outlined"
                                label="Demo link"
                                className="post-form-field"
                             
                            />

                            <TextField
                                type='text'
                                name='technology'
                                value={technology}
                                onChange={handleChange("technology")}
                                variant="outlined"
                                label="Technology"
                                className="post-form-field"
                            />

                            <TextField
                                  id="standard-multiline-static"
                                  className='post-description-field'
                                  name='description'
                                  label="Project description"
                                  multiline
                                  rows="4"
                                  defaultValue="Project description"
                                  variant="outlined"                                  
                                  margin="normal"
                                  onChange={handleChange("description")}
                                  value={description}
                                />

                              <TextField
                                        id="projectdate"
                                        type="date"
                                        value={values.projectdate}
                                        defaultValue={Date.now}
                                        onChange={handleChange('projectdate')}
                                        InputLabelProps={{
                                        shrink: true,
                                        }}
                                    />
                    
                                <div className="custom-file">
                                <input
                                  onChange={handleChange("photo")}
                                  type="file"
                                  name="photo"
                                  accept="image/*"
                                />
                                </div>
                          <input className='mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent submit-button' value='Submit' type="submit" />
                        
                          {/* <button className='mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent submit-button' value='Delete' text="DELETE" onClick={clickDelete} /> */}
                          <Button onClick={clickDelete} variant="outlined">DELETE</Button>
   
                        </form>
                      </div>
                    </div>


                </Fragment>
            )
    }

  

export default EditForm;