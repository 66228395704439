import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';


const Avatar = () => {
    const imageSize = {
        marginTop: '15px',
        minHeight: '130px',
        minWidth: '130px',
        borderRadius: '2%'
    };
    const fontSize = {
        marginTop: '7px',
        marginBottom: '5px'
    };
  
    const profilePic = require('./../images/profile-pic.png');
    const theme = useTheme();
    return (
        <Card className="avatar-card" sx={{ minWidth: '320px', maxWidth: '300px', maxHeight: '700px',  bgcolor: 'white',paddingLeft: '30px', paddingRight: '30px', backgroundColor: theme.palette.background.paper   }}>

            <Box style={{ display: 'flex', justifyContent: 'center', paddingTop:'40px', paddingBottom: '20px'}}>
                <img style={imageSize} alt="darren soohoo" src={profilePic} />


            </Box>
            <Stack direction='row' alignItems={'center'} justifyContent={'flex-end'} style={{ maxHeight: '80px' }}>
           
                <Typography variant="h5" color="text.secondary" sx={{padding:'15px', paddingRight:'25px'}} style={fontSize} > Darren SooHoo  </Typography>
               
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/darrensoohoo/">
                    <img src="https://img.icons8.com/color/40/000000/linkedin.png" alt="linkedin icon" />
                </a>
        
            </Stack>

        </Card>
    );
}

export default Avatar;
