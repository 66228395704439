import React from 'react';
import { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Stack from '@mui/material/Stack';
import './AboutMe.css'
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LazyLoad from 'react-lazyload';
import store from '../../store';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputBase from "@mui/material/InputBase";
import TextField from '@mui/material/TextField';

function AboutMe({ auth, isAuthenticated }) {
  const theme = useTheme();
  const profilePic = require('./../images/profile-pic.png');
  const [p1, setP1] = React.useState('');
  const [p2, setP2] = React.useState('');
  const [p3, setP3] = React.useState('');
  const [p4, setP4] = React.useState('');
  const [editMode, setEditMode] = React.useState(false);



  useEffect(() => {
 

console.log("auth", auth)
console.log("user", auth.user)
    fetch('/api/about/p1')
      .then((res) => res.json())
      .then((fetchedParagraph) => {
        setP1(fetchedParagraph.content);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });

    fetch('/api/about/p2')
      .then((res) => res.json())
      .then((fetchedParagraph) => {
        ;
        setP2(fetchedParagraph.content);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });

    fetch('/api/about/p3')
      .then((res) => res.json())
      .then((fetchedParagraph) => {
        setP3(fetchedParagraph.content);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });

    fetch('/api/about/p4')
      .then((res) => res.json())
      .then((fetchedParagraph) => {
        setP4(fetchedParagraph.content);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });

 
  }, []);

  const onChangeP1 = (e) => {
    setP1(e.target.value);
  };
  const onChangeP2 = (e) => {
    setP2(e.target.value);
  };
  const onChangeP3 = (e) => {
    setP3(e.target.value);
  };
  const onChangeP4 = (e) => {
    setP4(e.target.value);
  };

  const onSaveP1 = (e) => {
    fetch('/api/about/p1', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: p1 }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });
  };
  const onSaveP2 = (e) => {
    fetch('/api/about/p2', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: p2 }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });
  };
  const onSaveP3 = (e) => {
    fetch('/api/about/p3', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: p3 }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });
  };
  const onSaveP4 = (e) => {
    fetch('/api/about/p4', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ content: p4 }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
      })
      .catch((e) => {
        console.log(`An error occurred: ${e}`);
      });
  };

  return (
    <Card sx={{ minWidth: '200px', width: '100%', maxHeight: '1200px' }}>
      <CardActionArea >
        <CardContent sx={{ bgcolor: theme.palette.primary.main, color: 'white', maxHeight: '70px', pl: 2.5 }}>
          <h6>About</h6>

        </CardContent>
      </CardActionArea>


      <Box sx={{ padding: '20px' }} >
        <Stack direction='column' spacing={2} >
          <Box className="aboutme-avatar" sx={{ pt: 5 }}>
            <Stack direction='row'>
              <img
                src={profilePic}
                alt="darren soohoo"
                style={{

                  height: '130px',
                  width: '130px',
                  borderRadius: '50%',
                  marginLeft: '20px',
                  marginRight: '20px',
                  marginBottom: '0px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                }}
              />
              <Stack direction='column'>
                <Typography variant='h5' style={{ float: 'right' }}>Darren SooHoo</Typography>
                <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/darrensoohoo/" style={{ float: 'center' }} >
                  <img src="https://img.icons8.com/color/40/000000/linkedin.png" alt="linkedin icon" />
                </a>
              </Stack>
            </Stack>
          </Box>


          <Stack direction='column' spacing={1.5} sx={{ pt: 0 }}>
            {auth.user ? (
              <Stack direction='row' sx={{width:'100%', pr:0}} alignItems={'flex-start'} justifyContent={'flex-end'}>
                <Button onClick={() => setEditMode(!editMode)} variant="filled" size="small" style={{
                  color: 'black', backgroundColor: 'transparent', height: 10, padding:0, maxWidth:160, width: 'auto', 
                
                  }}>
                {editMode ? 'Stop editing' : 'Edit'}
              </Button>
</Stack>
            ) : null}

            {p1 && (
              <LazyLoad height={200} offset={100}>
                {editMode ? (
                  <>
                  <Stack direction='row' spacing={2} sx={{width:'100%', minWidth:500}} alignItems={'center'} justifyContent={'center'}>
                      <InputBase
                        id="p1"
                        name="p1"
                        multiline
                        placeholder="Paragraph 1"
                        defaultValue={p1}
                        type="search"
                        onChange={(e) => onChangeP1(e)}
                        sx={{ backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '3px' }}
                      />
                      <Button onClick={(e) => onSaveP1(e)} variant="outlined" size="small" style={{  color: 'black', backgroundColor: 'transparent', height: 40, width:'auto',border:'solid gray'  }}>Save</Button>
                  </Stack>
                  </>
                ):
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                    {p1}
                  </Typography>
                }

              </LazyLoad>
            )}


            {p2 && (
              <>
                {editMode ? (
                  <Stack direction='row' spacing={2} sx={{ width: '100%', minWidth: 500 }} alignItems={'center'} justifyContent={'center'}>
                    <InputBase
                      id="p2"
                      name="p2"
                      placeholder="Paragraph 2"
                      multiline
                      inputProps={{ "aria-label": "search google maps" }}
                      defaultValue={p2}
                      type="search"
                      onChange={(e) => onChangeP2(e)}
                      sx={{ backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '3px' }}
                    />
                    <Button onClick={(e) => onSaveP2(e)} variant="outlined" size="small" style={{ color: 'black', backgroundColor: 'transparent', height: 40, width: 'auto', border: 'solid gray' }}>Save</Button>
                  </Stack>
                ) :
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                    {p2}
                  </Typography>
                }

              </>
            )}


            {p3 && (
              <LazyLoad height={200} offset={100}>
                {editMode ? (
                  <Stack direction='row' spacing={2} sx={{ width: '100%', minWidth: 500 }} alignItems={'center'} justifyContent={'center'}>
                    <InputBase
                      id="p3"
                      name="p3"
                      placeholder="Paragraph 3"
                      multiline
                      inputProps={{ "aria-label": "search google maps" }}
                      defaultValue={p2}
                      type="search"
                      onChange={(e) => onChangeP3(e)}
                      sx={{ backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '3px' }}
                    />
                    <Button onClick={(e) => onSaveP3(e)} variant="outlined" size="small" style={{ color: 'black', backgroundColor: 'transparent', height: 40, width: 'auto', border: 'solid gray' }}>Save</Button>
                  </Stack>
                ) :
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                    {p3}
                  </Typography>
                }

              </LazyLoad>
            )}


            {p4 && (
              <LazyLoad height={200} offset={100}>
                {editMode ? (
                  <Stack direction='row' spacing={2} sx={{ width: '100%', minWidth: 500 }} alignItems={'center'} justifyContent={'center'}>
                    <InputBase
                      id="p4"
                      name="p4"
                      placeholder="Paragraph 4"
                      multiline
                      inputProps={{ "aria-label": "search google maps" }}
                      defaultValue={p4}
                      type="search"
                      onChange={(e) => onChangeP4(e)}
                      sx={{ backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '3px' }}
                    />
                    <Button onClick={(e) => onSaveP4(e)} variant="outlined" size="small" style={{ color: 'black', backgroundColor: 'transparent', height: 40, width: 'auto', border: 'solid gray' }}>Save</Button>
                  </Stack>
                ) :
                  <Typography variant="body1" sx={{ whiteSpace: 'pre-line', wordWrap: 'break-word' }}>
                    {p4}
                  </Typography>
                }

              </LazyLoad>
            )}





          </Stack>


        </Stack>
      </Box>

    </Card>
  );
}


export default AboutMe;