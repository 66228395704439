import React, { Fragment, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { connect } from 'react-redux';
//import './Navbar.css';
import { logout } from '../../actions/auth';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import getTheme from '../../theme'
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

const NavAppBar = ({ logout, auth: { user }, darkMode, handleThemeModeSwitch }) => {
    useEffect(() => { });
    const theme = useTheme();

    const Logout = () => {
        logout();
        window.location.reload();
    };

    const onThemeModeSwitch = () => {
        handleThemeModeSwitch();
    }
    return (

        <Box sx={{ flexGrow: 1 }}>
            <AppBar style={{ backgroundColor: 'transparent' }} position="static">
  
                <Toolbar>




                {user && (
                    <Typography color={theme.palette.primary.color} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Darren SooHoo
                    </Typography>
                )}

                <Stack direction='row' alignItems={'center'} justifyContent={'flex-end'}>
                {user && (
                    <Stack direction="row" spacing={2} >
                        <Link style={{ textDecoration: 'none', color: theme.palette.primary.color }} variant="body2" to='/'>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                                Home

                            </Typography>
                        </Link>

                        <Link style={{ textDecoration: 'none', color: theme.palette.primary.color }} variant="body2" to='post-form'>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Post
                            </Typography>

                        </Link>
                        <Link style={{ textDecoration: 'none', color: theme.palette.primary.color }} variant="body2" onClick={Logout} to='/'>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                Log out
                            </Typography>

                        </Link>


               
                    </Stack>
                        )}


                        <Stack  sx={{pl:2, pr:2}} direction='column' alignItems={'center'} justifyContent={'flex-end'} onClick={onThemeModeSwitch}>
                                {darkMode ?   <DarkModeIcon  style={{ color: 'white' }} ></DarkModeIcon> :<LightModeOutlinedIcon style={{ color: 'darkslategrey' }} >light_mode</LightModeOutlinedIcon>}  
                            </Stack>

                </Stack>
                  

                           
                </Toolbar>
                          
            </AppBar>
        </Box>

    );
}


NavAppBar.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool
};

const mapStateToProps = state => ({
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps, { logout })( NavAppBar );
