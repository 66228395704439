import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';




const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '50vh'
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    paddingBottom: '80px',
    backgroundColor: 'white'
  },
}));

export default function StickyFooter() {
  const classes = useStyles();

  return (
              <div className={[classes.root, classes.main, classes.footer]}>
                <CssBaseline />
                <Container component="main" className={classes.main} maxWidth="sm">
                  <Typography variant="h2" component="h1" gutterBottom>
                    Driven by curiosity
                  </Typography>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {'Inspired by creativity'}
                  
                  </Typography>
                </Container>   
              </div>
  );
}