import React, { Fragment,  useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavAppBar from './components/layout/NavAppBar';
import Landing from './components/layout/Landing';
import Routes from './components/routing/Routes';
//https://portfolio-backend-production-73fe.up.railway.app/
// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';


import { ThemeProvider } from '@mui/material/styles';
import getTheme from "./theme";
import colors from './colors';




if (localStorage.token) {
  setAuthToken(localStorage.token);
}

const App = () => {
 //const classes = useStyles();
 let color = colors[24].value;
 const [darkMode, setDarkMode] = useState(false);
 const [themeColor, setThemeColor] = React.useState(color);
const [ipAddress, setIpAddress] = useState(null);


 useEffect(() => {
   fetch('/api/visitors', {
     method: 'POST', // Set the HTTP method to 'POST'
     headers: {
       'Content-Type': 'application/json', // Set the Content-Type header to JSON
     },
     // If you have data to send in the request body, use the 'body' option
     // For example, sending a JSON object as the request body:
     // body: JSON.stringify({ key1: 'value1', key2: 'value2' }), // Replace with your data
   })
     .then((response) => response.json())
     .then((data) => {
       // Handle the response data here
       console.log('IP Address:', data.ip);
       setIpAddress(data.ip);
     })
     .catch((error) => console.error('Error fetching IP:', error));

 },[])
 const handleThemeModeSwitch = () => {
   setDarkMode(!darkMode);
 };
 const handleThemeColorChange = (newValue) => {
   setThemeColor(newValue);
 };

 const theme = getTheme(darkMode, themeColor);
  return (

    <ThemeProvider theme={theme}>
          <Provider store={store}>
      <Router>
        <Fragment>
          <NavAppBar darkMode={darkMode}  handleThemeModeSwitch={handleThemeModeSwitch}/>
          <Switch>
          <Route
                exact
                path="/"
                render={(props) => <Landing {...props} darkMode={darkMode} />}
              />
              <Route component={Routes} />
          </Switch>
        </Fragment>
      </Router>
    </Provider>
    </ThemeProvider>
  );
};

export default App;
