import React from "react";

const ShowImage = ({ item }) => (
    <div className="product-img">
        <img
            src={`/api/posts/post/photo/${item._id}`}
            alt={item.name}
            className="mb-3"
            style={{ maxHeight: "100%", maxWidth: "100%" }}
        />
    </div>
);

export default ShowImage;
