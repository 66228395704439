import { applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { configureStore } from '@reduxjs/toolkit';

const initialState = {};

const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

const store = configureStore({
  reducer: rootReducer, // Replace with your actual reducer
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Add any middleware configuration here if needed
        initialState,
 ...middleware
    }),
});

export default store;
